<template>
  <div>
    <div style="height: 30px;margin: 25px 0 5px 0;display: flex;align-items: center">
      <el-breadcrumb class="bread" separator-class="el-icon-arrow-right">
        <el-breadcrumb-item class="breaditem"><span style="color: #909399">书目检索</span></el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div style="height: 72px">
      <div class="selectdiv" style="height: 72px"
           :class="{ sticky: isSticky }" ref="stickyDiv">
        <el-input class="family" size="small" v-model.trim="query.value"
                  @keyup.enter.native="selectBook(false)" clearable maxlength="25"
                  style="width: 300px !important;margin-right: 10px">
          <el-select class="family" size="small" slot="prepend" v-model="query.type"
                     @change="typeChange" style="width: 75px">
            <el-option value="isbn" label="书号"
                       style="font-family: 'Segoe UI', 'Roboto', arial, sans-serif;font-size: 13px"/>
            <el-option value="title" label="书名"
                       style="font-family: 'Segoe UI', 'Roboto', arial, sans-serif;font-size: 13px"/>
          </el-select>
        </el-input>
        <el-checkbox style="margin-right: 10px" size="small" @change="whiteChange"
                     v-model="white" label="只看关注" border/>
        <el-button type="primary" icon="el-icon-search" size="small"
                   @click="selectBook(false)">查询
        </el-button>
        <el-button type="success" icon="el-icon-search"
                   size="small" @click="openBatchSearch">便捷查询
        </el-button>
        <div style="width: 396px;display: flex;justify-content: flex-end;align-items: center">
          <el-button v-if="isAdvance" type="primary" icon="el-icon-search"
                     size="small" @click="advance">高级查询
          </el-button>
        </div>
      </div>
    </div>
    <div style="height: 50px">
      <div style="height: 27px"/>
      <div v-if="total>0" style="height: 23px;line-height: 13px">
        <span class="span" style="color: #606266">搜索出 </span>
        <span class="span" style="color: #409EFF">{{total}}</span>
        <span class="span" style="color: #606266"> 条结果</span>
      </div>
    </div>
    <el-table border stripe size="small" :data="books" :row-style="{height:'42px'}"
              :header-cell-style="{background:'#ECF5FF',fontWeight: 'normal'}">
      <el-table-column label="书号" prop="isbn" width="110" :resizable="false"/>
      <el-table-column label="书名" prop="title" width="286" :resizable="false"/>
      <el-table-column label="定价" prop="price" width="70" :resizable="false" :formatter="priceFormat"/>
      <el-table-column label="作者" prop="author" width="120" :resizable="false"/>
      <el-table-column label="出版社" prop="publisher" width="120" :resizable="false"/>
      <el-table-column label="出版时间" prop="pubdate" width="85" :resizable="false"
                       :formatter="dateFormat"/>
      <el-table-column label="中图分类" prop="sort" width="85" :resizable="false"/>
      <el-table-column label="库存" prop="num" width="60" :resizable="false"/>
      <el-table-column label="供货商" width="63" :resizable="false" header-align="center">
        <template slot-scope="scope">
          <div class="scope">
            <el-button :disabled="show || scope.row.num===0"
                       :type="(show || scope.row.num===0)?'info':'primary'"
                       :plain="show || scope.row.num===0"
                       class="buttonTable" size="mini" icon="el-icon-search"
                       @click="getProduct(scope.row)"/>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev,pager,next" :page-size="50" :total="total"
                   :current-page="query.page" @current-change="pageChange"
                   :hide-on-single-page="true"/>
    <div v-if="show" style="margin-top: 30px">
      <div style="height: 40px">
        <div style="height: 17px;margin-top: 20px"/>
        <div v-if="ptotal>0" style="height: 23px;line-height: 13px">
          <span class="span" style="color: #606266">搜索出 </span>
          <span class="span" style="color: #409EFF">{{ptotal}}</span>
          <span class="span" style="color: #606266"> 条结果</span>
          <span class="span" style="color: #606266;margin-left: 30px">库存 </span>
          <span class="span" style="color: #409EFF">{{pnum}}</span>
        </div>
      </div>
      <el-table border stripe size="small" :data="products" :row-style="{height:'42px'}"
                :header-cell-style="{background:'#ECF5FF',fontWeight: 'normal'}"
                style="width: 871px">
        <el-table-column label="供货商" width="350" :resizable="false">
          <template slot-scope="scope">
            <div style="float: left">
              <el-tag v-if="scope.row.seller.restdate!==0" type="warning"
                      style="margin-left: -11px">
                休息至 {{restFormat(scope.row.seller.restdate)}}
              </el-tag>
              <div>
                <span :style="{marginRight:'5px',color:(scope.row.seller.restdate!==0?'#C0C4CC':'#606266')}">
                {{scope.row.seller.name}}</span>
                <el-tag v-if="scope.row.seller.white" type="success">已关注</el-tag>
                <el-tag type="primary">{{getFormat(scope.row.seller.getmode)}}</el-tag>
                <el-tag type="danger">{{backFormat(scope.row.seller.backmode)}}</el-tag>
                <el-tag v-if="scope.row.seller.afternoon" type="info">下午也配</el-tag>
                <el-tag v-if="scope.row.seller.single" type="info">不留单本</el-tag>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="更新时间" prop="seller.upddate" width="100" :resizable="false"
                         :formatter="timeFormat"/>
        <el-table-column label="库位" prop="position" width="150" :resizable="false"/>
        <el-table-column label="折扣" width="65" :resizable="false">
          <template slot-scope="scope">
            <div style="float: left">
              <span style="margin-right: 5px">{{discountFormat(scope.row.discount)}}</span>
              <el-tooltip v-if="scope.row.seller.cut!==undefined"
                          placement="top-start" effect="light" :open-delay="300">
                <span slot="content" style="color: #C0C4CC">同一订单内所有图书实洋</span>
                <br slot="content"/><br slot="content"/>
                <span slot="content" style="white-space: pre-wrap">{{scope.row.seller.cut}}</span>
                <el-tag type="success" style="cursor: default">惠</el-tag>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="售价" width="75" :resizable="false">
          <template slot-scope="scope">
            {{parseFloat(scope.row.discount*scope.row.book.price).toFixed(2)}}
          </template>
        </el-table-column>
        <el-table-column label="库存" prop="num" width="60" :resizable="false"/>
        <el-table-column label="加购" width="70" :resizable="false" header-align="center">
          <template slot-scope="scope">
            <div class="scope">
              <el-badge :value=scope.row.cartNum :max="99">
                <el-popover placement="right" v-model="scope.row.visible">
                  <div style="display: flex;justify-content: center;align-items: center">
                    <el-input-number class="family1" v-model="scope.row.order" size="mini"
                                     :min="1" :max="scope.row.num"/>
                    <el-button class="buttonPopo" size="mini" type="success"
                               @click="addCart(scope.row)" icon="el-icon-check"
                               :loading="scope.row.loading"/>
                  </div>
                  <el-button slot="reference" class="buttonTable" size="mini" type="success"
                             icon="el-icon-shopping-cart-2" @click="initOrderNum(scope.row)"
                             :loading="scope.row.loading"/>
                </el-popover>
              </el-badge>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination background layout="prev,pager,next" :page-size="50" :total="ptotal"
                     :current-page="pquery.page" @current-change="ppageChange"
                     :hide-on-single-page="true"/>
    </div>
    <el-dialog :visible.sync="showVip" custom-class="info" append-to-body>
      <div style="height: 130px;display: flex;flex-direction: column;
      align-items: center;justify-content: center">
        <span style="color: #EB6100;font-size: 16px;margin-top: 10px">
          该功能需开通会员！</span>
        <span style="color: #909399;font-size: 12px;margin-top: 20px">
          1.微信扫描下方小程序码，进入书店宝小秘书</span>
        <span style="color: #909399;font-size: 12px;margin-top: 10px">
          2.点击用户会员信息，进入充值页面续费开通</span>
      </div>
      <div style="height: 200px;display: flex;flex-direction: row;
      align-items: center;justify-content: center">
        <div>
          <img src="../assets/wxxcx.jpg" height="160"/>
        </div>
        <div style="margin-left: 30px">
          <img src="../assets/wxvip.jpg" height="160"/>
        </div>
      </div>
    </el-dialog>
    <el-drawer :visible.sync="isShowBatchSearch" append-to-body size="571px"
               :destroy-on-close="true" :with-header="false"
               style="margin-top: 51px;margin-right: 1px;margin-bottom: 1px"
               @opened="selectBatchSearch">
      <div style="display: flex;justify-content: start;align-items: center;margin:30px 30px">
        <el-button type="warning" icon="el-icon-upload2" size="small"
                   @click="openUploadBatch">上传
        </el-button>
        <el-popover placement="right" style="margin-left: 10px" v-model="visibleClr">
          <div style="display: flex;justify-content: center;align-items: center">
            <span class="spanPopo">确定清空？</span>
            <el-button class="buttonPopo" size="mini" type="success" @click="clrBatchSearch"
                       icon="el-icon-check" :loading="loadingClr"/>
          </div>
          <el-button slot="reference" type="danger" icon="el-icon-delete"
                     size="small" :loading="loadingClr">清空
          </el-button>
        </el-popover>
      </div>
      <div v-if="btotal>0" style="height: 23px;line-height: 13px;margin-left: 30px;
      display: flex;justify-content: space-between">
        <div>
          <span class="span" style="color: #606266">上传了 </span>
          <span class="span" style="color: #409EFF">{{btotal}}</span>
          <span class="span" style="color: #606266"> 条数据</span>
          <span class="span" style="margin-left: 30px;color: #606266">待检索 </span>
          <span class="span" style="color: #F56C6C">{{bnosearch}}</span>
          <span class="span" style="margin-left: 30px;color: #606266">已检索 </span>
          <span class="span" style="color: #67C23A">{{bissearch}}</span>
        </div>
        <div>
          <span class="span" style="margin-right: 30px;color: #C0C4CC">*置顶显示待检索数据</span>
        </div>
      </div>
      <el-table border stripe size="small" :data="batchs" :row-style="{height:'42px'}"
                :header-cell-style="{background:'#ECF5FF',fontWeight: 'normal'}"
                style="margin-left: 30px;margin-right: 30px;margin-bottom: 30px;width: 511px">
        <el-table-column label="图书信息" width="200" :resizable="false">
          <template slot-scope="scope">
            <div style="display: flex;justify-content: space-between;align-items: center">
              <el-link class="link" @click="batchSearch(scope.row)" :underline="false">
                {{scope.row.book}}
              </el-link>
              <el-tag v-if="scope.row.flag===false" type="danger"
                      style="margin-left: -1px;margin-right: -1px">待检索
              </el-tag>
              <el-tag v-if="scope.row.flag===true" type="success"
                      style="margin-left: -1px;margin-right: -1px">已检索
              </el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="订数" prop="num" width="60" :resizable="false"/>
        <el-table-column label="备注" prop="comment" width="250" :resizable="false"/>
      </el-table>
      <el-pagination background layout="prev,pager,next" :page-size="10" :total="btotal"
                     :current-page="bpage" @current-change="bpageChange"
                     :hide-on-single-page="true" style="margin-left: 30px;margin-bottom: 30px"/>
      <el-dialog :visible.sync="visibleUpl" custom-class="upload" append-to-body
                 :close-on-click-modal="false">
        <div style="display: flex;flex-direction: column;width: 200px">
        <span class="span">
          上传说明<el-button type="text" class="textbutton" @click="downloadExp">下载示例</el-button><br><br>
          ● 文件格式为 xls 或 xlsx<br><br>
          ● 文件大小不超过 1MB<br><br>
          ● 最多读取前 1000 行数据<br><br>
          ● 上传成功后会覆盖原先数据
        </span>
          <div style="display: flex;justify-content: space-between;margin-top: 30px">
            <el-upload :action="this.baseURL+'uploadBatchSearch'" :headers="{token: token}"
                       :before-upload="uploadBef" :on-success="uploadSuc" :on-error="uploadErr"
                       accept=".xls,.xlsx" :show-file-list="false">
              <el-button type="primary" icon="el-icon-folder" size="small"
                         :loading="loadingUpl">{{loadingUpl?'正在上传':'选择文件'}}
              </el-button>
            </el-upload>
            <el-button v-if="!loadingUpl" size="small" @click="closeUploadBatch">取消</el-button>
          </div>
        </div>
      </el-dialog>
    </el-drawer>
  </div>
</template>

<script>
  export default {
    mounted () {
      window.addEventListener('scroll', this.handleScroll)
    },
    beforeDestroy () {
      window.removeEventListener('scroll', this.handleScroll)
    },
    created () {
      this.getBuyerGetInfo()
      this.white = window.localStorage.getItem('white') === 'true'
    },
    data () {
      return {
        query: {
          type: 'isbn',
          value: '',
          white: null,
          batch: null,
          page: 1
        },
        books: [],
        total: 0,

        show: false,
        pquery: {
          bookId: null,
          white: null,
          page: 1
        },
        products: [],
        ptotal: 0,
        pnum: 0,

        white: false,
        showVip: false,
        isAdvance: false,

        token: window.localStorage.getItem('token'),
        isShowBatchSearch: false,
        visibleClr: false,
        loadingClr: false,
        visibleUpl: false,
        loadingUpl: false,
        btotal: 0,
        bissearch: 0,
        bnosearch: 0,
        bpage: 1,
        batchs: [],

        isSticky: false
      }
    },
    methods: {
      priceFormat (row) {
        return parseFloat(row.price).toFixed(2)
      },
      dateFormat (row) {
        if (row.pubdate === undefined) {
          return
        }
        const date = new Date(row.pubdate)
        const y = date.getFullYear()
        let MM = date.getMonth() + 1
        MM = MM < 10 ? ('0' + MM) : MM
        let d = date.getDate()
        d = d < 10 ? ('0' + d) : d
        return y + '-' + MM + '-' + d
      },
      typeChange () {
        this.query.value = ''
      },
      whiteChange () {
        window.localStorage.setItem('white', this.white)
      },
      selectBook (batch) {
        this.query.batch = batch
        this.query.page = 1
        this.getBookList()
      },
      pageChange (page) {
        this.query.batch = false
        this.query.page = page
        this.getBookList()
      },

      async getBookList () {
        if (this.query.value === '') {
          return this.$msg.warning('请输入搜索内容！')
        }

        const loading = this.loading()

        try {
          this.show = false
          this.pquery.bookId = null
          this.query.white = this.white

          const { data: res } = await this.$http.get('getBookList', { params: this.query })

          loading.close()

          if (!this.isLogin(res)) {
            return
          }
          if (res.code !== 0) {
            this.total = 0
            this.books = []
            if (res.msg === '该功能需开通会员！') {
              this.showVip = true
            } else {
              this.$msg.warning(res.msg)
            }
            return
          }

          this.total = res.data.total
          this.books = res.data.data

          if (this.total === 0) {
            this.$msg.warning('暂无相关图书信息 / 或库存信息！')
          }

          if (this.total === 1) {
            this.query.value = ''
          }

          if (this.total === 1 && this.books[0].num > 0) {
            this.show = true
            this.pquery.bookId = this.books[0].id
            this.pquery.white = this.white
            this.selectProduct()
          }

          document.documentElement.scrollTop = document.body.scrollTop = 0
        } catch (e) {
          loading.close()
          this.total = 0
          this.books = []
          this.show = false
          this.pquery.bookId = null
          this.$msg.error(this.netError)
        }
      },
      getProduct (row) {
        sessionStorage.setItem('id', row.id)
        sessionStorage.setItem('mark', '图书：' + row.isbn + ' ▪ ' + row.title +
          ' ▪ ¥' + row.price + (row.publisher === undefined ? '' : ' ▪ ' + row.publisher))
        const route = this.$router.resolve({ name: 'product' })
        window.open(route.href, '_blank')
      },

      getFormat (value) {
        if (value === '0') {
          return '自提'
        } else if (value === '1') {
          return '兴茂'
        } else if (value === '2') {
          return '代发'
        } else {
          return '书店宝'
        }
      },
      backFormat (value) {
        if (value === '0') {
          return '不可退'
        } else if (value === '1') {
          return '1天可退'
        } else if (value === '2') {
          return '3天可退'
        } else if (value === '3') {
          return '7天可退'
        } else if (value === '4') {
          return '15天可退'
        } else {
          return '30天可退'
        }
      },
      discountFormat (value) {
        return parseFloat(value).toFixed(2)
      },
      timeFormat (row) {
        const date = new Date(row.seller.upddate)
        let MM = date.getMonth() + 1
        MM = MM < 10 ? ('0' + MM) : MM
        let d = date.getDate()
        d = d < 10 ? ('0' + d) : d
        let h = date.getHours()
        h = h < 10 ? ('0' + h) : h
        let m = date.getMinutes()
        m = m < 10 ? ('0' + m) : m
        return MM + '-' + d + '　' + h + ':' + m
      },
      restFormat (time) {
        const date = new Date(time)
        const Y = date.getFullYear()
        let MM = date.getMonth() + 1
        MM = MM < 10 ? ('0' + MM) : MM
        let d = date.getDate()
        d = d < 10 ? ('0' + d) : d
        let h = date.getHours()
        h = h < 10 ? ('0' + h) : h
        let m = date.getMinutes()
        m = m < 10 ? ('0' + m) : m
        return Y + '-' + MM + '-' + d + ' ' + h + ':' + m
      },
      initOrderNum (row) {
        row.order = 1
      },
      selectProduct () {
        this.pquery.page = 1
        this.getProductList()
      },
      ppageChange (page) {
        this.pquery.page = page
        this.getProductList()
      },

      async getProductList () {
        if (this.pquery.bookId === null) {
          return
        }

        const loading = this.loading()

        try {
          const { data: res } = await this.$http.get('getProductList',
            { params: this.pquery })

          loading.close()

          if (!this.isLogin(res)) {
            return
          }
          if (res.code !== 0) {
            this.ptotal = 0
            this.products = []
            this.$msg.warning(res.msg)
            return
          }

          this.ptotal = res.data.total
          this.pnum = res.data.count
          this.products = res.data.data
          this.products = this.products.map(item => {
            this.$set(item, 'order', 1)
            this.$set(item, 'visible', false)
            this.$set(item, 'loading', false)
            return item
          })
          document.documentElement.scrollTop = document.body.scrollTop = 0
        } catch (e) {
          loading.close()
          this.ptotal = 0
          this.products = []
          this.$msg.error(this.netError)
        }
      },
      async addCart (row) {
        if (row.order === undefined) {
          this.$msg.warning('请输入订数！')
          return
        }
        this.$set(row, 'visible', false)
        this.$set(row, 'loading', true)

        try {
          const { data: res } = await this.$http.post('addCart',
            {
              bookId: row.book.id,
              sellerId: row.seller.id,
              num: row.order
            })

          if (!this.isLogin(res)) {
            this.$set(row, 'loading', false)
            return
          }

          this.$set(row, 'loading', false)
          if (res.code !== 0) {
            this.$msg.warning(res.msg)
          } else {
            if (row.cartNum !== undefined) {
              row.cartNum += row.order
            } else {
              row.cartNum = row.order
            }
            this.$set(row, 'order', 1)
            this.$msg.success('加入成功！')
          }
        } catch (e) {
          this.$set(row, 'loading', false)
          this.$msg.error(this.netError)
        }
      },

      async getBuyerGetInfo () {
        try {
          const { data: res } = await this.$http.get('getBuyerGetInfo')

          if (!this.isLogin(res)) {
            return
          }
          if (res.code !== 0) {
            this.$msg.warning(res.msg)
          } else {
            this.isAdvance = res.data.advance
          }
        } catch (e) {
          this.$msg.error(this.netError)
        }
      },
      advance () {
        const route = this.$router.resolve({ name: 'advance' })
        window.open(route.href, '_blank')
      },

      openBatchSearch () {
        this.isShowBatchSearch = true
        this.query.type = 'isbn'
        this.query.value = ''
        this.total = 0
        this.books = []
        this.ptotal = 0
        this.products = []
        this.show = false
      },
      selectBatchSearch () {
        this.bpage = 1
        this.getBatchSearchList()
      },
      bpageChange (page) {
        this.bpage = page
        this.getBatchSearchList()
      },
      async getBatchSearchList () {
        const loading = this.loading()

        try {
          const { data: res } = await this.$http.get('getBatchSearchList',
            { params: { page: this.bpage } })

          loading.close()

          if (!this.isLogin(res)) {
            return
          }
          if (res.code !== 0) {
            this.btotal = 0
            this.batchs = []
            return this.$msg.warning(res.msg)
          }

          this.btotal = res.data.total
          this.bnosearch = res.data.total - res.data.count
          this.bissearch = res.data.count
          this.batchs = res.data.data
          document.documentElement.scrollTop = document.body.scrollTop = 0
        } catch (e) {
          loading.close()
          this.btotal = 0
          this.batchs = []
          this.$msg.error(this.netError)
        }
      },
      batchSearch (row) {
        this.isShowBatchSearch = false
        this.query.type = 'isbn'
        this.query.value = row.book
        this.selectBook(true)
        this.updBatchSearch(row.id)
      },
      async updBatchSearch (id) {
        try {
          await this.$http.get('updBatchSearch',
            { params: { id: id } })
        } catch (e) {
        }
      },
      async clrBatchSearch () {
        this.visibleClr = false
        this.loadingClr = true

        try {
          const { data: res } = await this.$http.get('clrBatchSearch')
          this.loadingClr = false

          if (!this.isLogin(res)) {
            return
          }
          if (res.code !== 0) {
            return this.$msg.warning(res.msg)
          }

          this.btotal = 0
          this.batchs = []
          this.$msg.success('清空成功！')
        } catch (e) {
          this.loadingClr = false
          this.$msg.error(this.netError)
        }
      },
      openUploadBatch () {
        this.visibleUpl = true
      },
      closeUploadBatch () {
        this.visibleUpl = false
      },
      async downloadExp () {
        try {
          const res = await this.$http.get('downloadBatchExp',
            { responseType: 'blob' })

          if (res.headers['content-type'] !== ('application/excel')) {
            const reader = new FileReader()
            reader.readAsText(res.data)
            reader.onload = e => {
              const data = JSON.parse(e.target.result)
              if (!this.isLogin(data)) {
                return
              }
              this.$msg.warning(data.msg)
            }
            return
          }

          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          link.download = '上传示例.xlsx'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          window.URL.revokeObjectURL(url)
          this.$msg.success('下载成功！')
        } catch (e) {
          this.$msg.error(this.netError)
        }
      },
      uploadBef (file) {
        const type = file.name.substring(file.name.lastIndexOf('.') + 1)
        if (type !== 'xls' && type !== 'xlsx') {
          this.$msg.warning('文件格式需为 xls 或 xlsx！')
          return false
        }
        if (file.size > 1024 * 1024) {
          this.$msg.warning('文件大小不能超过 1MB！')
          return false
        }
        this.loadingUpl = true
      },
      uploadErr () {
        this.loadingUpl = false
        this.$msg.error(this.netError)
      },
      uploadSuc (res) {
        this.loadingUpl = false

        if (!this.isLogin(res)) {
          return
        }
        if (res.code !== 0) {
          this.$msg.warning(res.msg)
          return
        }

        this.visibleUpl = false
        this.$msg.success('上传成功！')
        this.selectBatchSearch()
      },

      handleScroll () {
        const scrollTop = window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop
        const offsetTop = this.$refs.stickyDiv.offsetTop
        this.isSticky = scrollTop > offsetTop - (this.isSticky ? -10 : 50)
      }
    }
  }
</script>

<style lang="less" scoped>
  .sticky {
    display: flex;
    justify-content: left;
    align-items: center;
    position: fixed;
    top: 50px;
    z-index: 1000;
    background-color: #CCE8CF;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .3);
  }

  /deep/ .family input {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 13px;
  }

  /deep/ .family1 input {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 12px;
  }

  /deep/ .el-button {
    height: 32px !important;
    font-size: 13px;
    padding: 0 15px;
  }

  .scope {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /deep/ .buttonTable {
    width: 32px !important;
    height: 22px !important;
    font-size: 12px;
    padding: 0;
    margin: 0 5px;
  }

  .span {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 13px;
  }

  /deep/ .buttonPopo {
    width: 40px !important;
    height: 28px !important;
    font-size: 12px;
    padding: 0;
    margin: 0 0 0 10px;
  }

  .spanPopo {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 13px;
  }

  .mark {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    width: 922px;
    height: 15px;
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 13px;
    line-height: 15px;
  }

  .el-input-number {
    width: 100px !important;
  }

  .el-badge {
    position: absolute;
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 12px;
  }

  .link {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 12px;
  }

  /deep/ .el-badge__content.is-fixed {
    transform: translateY(-45%) translateX(70%) !important;
  }

  /deep/ .el-dialog__header {
    display: none;
  }

  /deep/ .el-dialog__body {
    padding: 0;
  }

  /deep/ .info {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 !important;
    width: 400px;
    height: 330px;
  }

  /deep/ .el-table td,
  /deep/ .el-table th.is-leaf {
    border-bottom: 0.5px solid #EBEEF5 !important;
  }

  /deep/ .el-table--border th,
  /deep/ .el-table--border th.gutter:last-of-type {
    border-bottom: 0.5px solid #EBEEF5 !important;
  }

  /deep/ .el-table--border td,
  /deep/ .el-table--border th {
    border-right: 0.5px solid #EBEEF5 !important;
  }

  /deep/ .upload {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 !important;
    width: 300px;
    height: 334px;
  }

  /deep/ .el-drawer__body {
    overflow: auto;
  }

  /deep/ .el-drawer__container ::-webkit-scrollbar {
    display: none;
  }

  /deep/ .el-drawer {
    border-radius: 2px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .3);
    background-color: #FCFCFC;
  }

  /deep/ .el-drawer:focus {
    outline: none;
  }
</style>
